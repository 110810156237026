import React, { useState } from 'react'

import { Text } from 'components/common/styles'
import Card from 'assets/images/about/tilted_card.svg'

import {
  Content,
  Container,
  Title,
  SubTitle,
  Number,
  Image,
  Wrapper,
  ButtonsContainer,
  CardContainer,
  SomeContainer,
  Year,
  ItemContainer,
  Item,
  Button,
} from './styles'


const IndustrySection = () => {
  const [show, setShow] = useState(true)

  return (
    <Container>
      <Title>ComCard is coming to your industry.</Title>
      <Content>
        <CardContainer>
          <Image src={Card} alt="card"/>
        </CardContainer>
        <SomeContainer>
          <ButtonsContainer>
            <Wrapper>
              <Year onClick={() => setShow(true)}>2020</Year>
              <Year onClick={() => setShow(false)} >2021</Year>
            </Wrapper>
            <Wrapper line={true} margin>
              <Button active={show} />
              <Button active={!show} />
            </Wrapper>
          </ButtonsContainer>
          <ItemContainer isVisible={show}>
            <Item>
              <Year className="year">2020</Year>
              <Number>01</Number>
              <SubTitle>Construction</SubTitle>
            </Item>
            <Item>
              <Number>02</Number>
              <SubTitle>Interior design</SubTitle>
            </Item>
          </ItemContainer>
          <ItemContainer isVisible={!show}>
            <Item>
              <Year className="year">2021</Year>
              <Number>01</Number>
              <SubTitle>Manufacturing</SubTitle>
            </Item>
            <Item>
              <Number>02</Number>
              <SubTitle>Fashion</SubTitle>
            </Item>
          </ItemContainer>
        </SomeContainer>
      </Content>
    </Container>
  )
}

export default IndustrySection
