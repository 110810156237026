import styled from '@emotion/styled'

import { Text as TextComponent } from 'components/common/styles'

export const Container = styled.section`
  display: flex;
  align-items: center;
  margin: 120px 8vw;
  
  @media screen and (max-width: 900px) {
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 60px;
  }
`

export const Title = styled.h2`
  flex: 1;
  margin-right: 8vw;
  font-size: 34px;
  font-weight: bold;
  line-height: 50px;
  
  @media screen and (max-width: 900px) {
    margin-right: 0;
    margin-bottom: 16px;
    font-size: 30px;
    line-height: 45px;
  }
`

export const Text = styled(TextComponent)`
  font-weight: 400;
  color: #000;
  font-size: 20px;
  text-align: center;
  line-height: 1.8;
`
