import styled from '@emotion/styled'
import { Text as TextComponent } from 'components/common/styles'

export const Container = styled.section`
  width: 100%;
  margin-bottom: 120px;
  
  @media screen and (max-width: 1024px ) {
    margin-bottom: 60px;
  }
`

export const Title = styled.h2`
  font-size: 34px;
  font-weight: bold;
  line-height: 50px;
  margin-bottom: 24px;
  
  @media screen and (max-width: 900px) {
    font-size: 30px;
    line-height: 45px;
  }
`

export const InfoContainer = styled.div`
  align-self: center;
  width: 45%;
  padding-right: 8vw;
  padding-left: 100px;
  
  @media screen and (max-width: 1100px) {
    padding: 0 100px;
    width: 100%;
  }
  
  @media screen and (max-width: 768px) {
    padding: 0 17px;
    width: 100%;
  }
`

export const Block = styled.div`
  display: flex;
  
  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
  
`

export const SliderContainer = styled.div`
  position: relative;
  width: 55%;
  padding-top: 36px;
  padding-bottom: 35px;
  background: #e4f1ef;
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;

  .slick-slider {
    width: calc(100% + 57px);
    margin-left: -57px;
    user-select: none;  
    
    .slick-track {
      display: flex;
    }
  }
  
  @media screen and (max-width: 1100px) {
    width: 100%;
    margin-left: 16px;
    margin-top: 30px;
    
    .slick-slider {
      width: calc(100% + 16px);
      margin-left: -16px;
    }
  }
`

export const Content = styled.div`
   display: grid;
   grid-template-columns: 1fr minmax(0,1480px) 1fr;
   grid-template-rows: 1fr;
  
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    padding: 0;
  }
`

export const ItemContainer = styled.div`
  text-align: center;
  padding: 24px 24px 0 24px;
  outline: none;
`
export const ItemImage = styled.img`
  width: 200px;
  height: 280px;
  border-radius: 40px;
  overflow: hidden;
  box-shadow: 0px 5px 30px rgba(8, 34, 32, 0.2);
`

export const Controls = styled.div`
  margin-right: 70px;
  text-align: right;
`

type Props = {
  disabled?: boolean
}

export const Button = styled.button<Props>`
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  pointer-events: ${props => props.disabled ? 'none' : 'all'};
  
  opacity: ${props => props.disabled ? .5 : 1};
  
  transition: all ease-in-out 100ms;
     
  :first-of-type {
    margin-right: 50px;
  }
  
  &:hover {
    transform: scale(1.2);
  }
`

export const Name = styled(TextComponent)`
  color: #000;
  font-weight: 600;
  margin-top: 24px;
`

export const Position = styled(TextComponent)`
  margin-top: 4px;
  margin-bottom: 8px;
`

export const Description = styled(TextComponent)``

export const SliderNavigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  
  margin-top: 30px;
  margin-right: 60px;
`

export const EmptySpacing = styled.div``
export const FilledSpacing = styled.div`
  background-color: #E4F1EF;
`
