import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Link } from 'components/common/social'
import LinkedInIcon from 'components/icons/linkedin'

import {
  Block,
  Button,
  Container,
  Content,
  Description,
  EmptySpacing,
  FilledSpacing,
  InfoContainer,
  ItemContainer,
  ItemImage,
  Name,
  Position,
  SliderContainer,
  SliderNavigation,
  Title,
} from './styles'


import ChingisJPG from 'assets/images/about/chingis.jpg'
import AlexJPG from 'assets/images/about/alex.jpg'
import JohnJPG from 'assets/images/about/john.jpg'
import DarrenJPG from 'assets/images/about/darren.jpg'
import OmarJPG from 'assets/images/about/omar.jpg'

import ArrowLeftSVG from 'assets/images/icons/arrow_left.svg'
import ArrowRightSVG from 'assets/images/icons/arrow_right.svg'

const Item = ({ src, name, post, link, ...rest }) => (
  <ItemContainer {...rest}>
    <ItemImage src={src} />
    <Name>{name}</Name>
    <Position>{post}</Position>
    <Link Icon={LinkedInIcon} href={link} />
  </ItemContainer>
)

const FutureSection = () => {
  const sliderRef = React.useRef<Slider>()

  return (
    <Container>
      <Content>
        <EmptySpacing />
        <Block>
          <InfoContainer>
            <Title>Meet the team</Title>
            <Description>
              Our leadership team brings extensive experience founding startups, running SMBs, and developing various
              software and tech products. Now we've come together to build the next-generation corporate card, enabling
              businesses to save time and money while growing their customer base.
            </Description>
          </InfoContainer>
          <SliderContainer>
            <Slider
              ref={sliderRef}
              speed={500}
              infinite={false}
              slidesToShow={3}
              responsive={
                [
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      centerMode: true,
                    },
                  },
                ]
              }
              variableWidth={true}
              draggable={false}
              arrows={false}
              dots={false}
            >
              <Item
                src={ChingisJPG}
                name="Chingis Akatayev"
                post="CEO & Co-Founder"
                link="https://www.linkedin.com/in/chingisakatayev/"
              />
              <Item
                src={AlexJPG}
                name="Alex Bondarevskyi"
                post="CTO & Co-Founder"
                link="https://www.linkedin.com/in/alexbondarevskiy/"
              />
              <Item
                src={JohnJPG}
                name="John Vardaman"
                post="General Counsel"
                link="https://www.linkedin.com/in/john-vardaman-9b514b67/"
              />
              <Item
                src={DarrenJPG}
                name="Darren Anderson"
                post="Advisor"
                link="https://www.linkedin.com/in/darren-anderson-1561216/"
              />
              <Item
                src={OmarJPG}
                name="Omar Al-Alami"
                post="Advisor"
                link="https://www.linkedin.com/in/omaralalami/"
              />
            </Slider>
            <SliderNavigation>
              <Button onClick={() => sliderRef.current.slickPrev()}>
                <img src={ArrowLeftSVG} alt="Previous" />
              </Button>
              <Button onClick={() => sliderRef.current.slickNext()}>
                <img src={ArrowRightSVG} alt="Next" />
              </Button>
            </SliderNavigation>
          </SliderContainer>
        </Block>
        <FilledSpacing />
      </Content>
    </Container>
  )
}

export default FutureSection
