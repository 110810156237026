import React from 'react'

import { Container, Text } from './styles'

const InfoSection = () => (
  <Container>
    <Text>
      ComCard’s vision is to provide simple, innovative financial tools to business communities that empower them in the
      digital world to continuously achieve great things and do what they love.
    </Text>
  </Container>
)

export default InfoSection
