import styled from '@emotion/styled'

import Background from 'assets/images/about/card_background.svg'

export const Container = styled.section`
  margin-bottom: 120px;
  
   @media screen and (max-width: 1024px ) {
    margin-bottom: 60px;
  }
`

export const Title = styled.h2`
  font-size: 34px;
  font-weight: bold;
  line-height: 50px;
  text-align: center;
  
  margin-bottom: 100px;
  
  @media screen and  (max-width: 900px) {
    font-size: 30px;
    line-height: 45px;
  }
`

export const CardContainer = styled.div`
  position: relative;
  flex: 1;
  width: 576px;
  height: 576px;
  margin-right: 8vw;
  background-image: url("${Background}") ;
  //background-position: center;
  //background-size: cover;
      background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  
  @media screen and (max-width: 1200px) {
    width: 500px;
    height: 500px;
  }
  
   @media screen and (max-width: 1100px) {
    width: 400px;
    height: 400px;
  }
  
   @media screen and (max-width: 1000px) {
    flex: initial;
    margin-right: 0;
   
   }
   
   @media screen and (max-width: 768px) {
      width: 300px;
      height: 300px
   }
  
`

export const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) /*rotateX(-20deg) rotateY(45deg)*/;
  border-radius: 16px;
  height: 640px;


  @media screen and (max-width: 1300px) {
    transform: translate(-50%,-50%) /*rotateX(-20deg) rotateY(45deg)*/;
    height: 470px;
  }
  
   @media screen and (max-width: 1100px) {
    height: 400px;
   }
   
   @media screen and (max-width: 768px) {
    height: 300px;
   }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`

export const SomeContainer = styled.div`
  position: relative;
  flex: 1;
  align-self: center;
  max-width: 360px;
  margin-right: 8vw;
  border-left: 1px solid #6BCBC4;
  
  @media screen and (max-width: 1200px) {
    max-width: 300px
  }
  
  @media screen and (max-width: 1000px) {
    margin-top: 30px;
    margin-right: 0;
    border-left: transparent;
   }
`

export const Item = styled.div`
  position: relative;
  //position: absolute;
  //width: 378px;
  margin-top: 24px;
  padding-left: 60px;

  &:first-of-type {
    margin-top: 0;
  }

  &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background: ${props => props.theme.colors.green100};
    border-radius: 50%;
    left: -4.5px;
    top: 10px;
  }

  @media screen and (max-width: 1000px) {
    margin-top: 24px !important;

    .year {
      display: none;
    }

    &::before {
      position: initial;
    }
  }
`

export const SubTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`

export const Number = styled.span`
  position: absolute;
  left: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-transform: capitalize;
  color: #C7C7C8;
`

type ItemContainerProps = {
  isVisible?: boolean
}

export const ItemContainer = styled.div<ItemContainerProps>`
  position: relative;
  top: -10px;

  :last-of-type {
    top: auto;
    bottom: -21%;

    ${Item} {
      &:last-of-type:after {
        content: '';
        position: absolute;
        width: 1px;
        height: calc(100% - 18px);
        background: #f9f8f7;
        left: -1px;
        top: 18px;
      }
    }
  }

  &:not(:last-of-type) {
    ${Item}:last-of-type {
      padding-bottom: 80px;
    }
  }

  @media screen and (max-width: 1200px) {
    :last-of-type {
      bottom: -20px;
    }

    &:not(:last-of-type) {
      ${Item}:last-of-type {
        padding-bottom: 0;
      }
    }

    @media screen and (max-width: 1000px) {
      top: auto;
      bottom: auto !important;
      display: ${props => props.isVisible ? 'initial' : 'none'};
    }
  }
`

export const Year = styled.span`
position: absolute;
left: -80px;
font-weight: 600;
font-size: 22px;
line-height: 28px;
text-transform: capitalize;
color: #1D1E21;

@media screen and (max-width: 1000px) {
  cursor: pointer;
}

`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;

  span {
    position: static;
    text-align: center;
  }

  @media screen and (min-width: 1001px) {
    display: none;
  }
`

type ButtonProps = {
  active: boolean
}

export const Button = styled.button<ButtonProps>`
   width: 8px;
   height: 8px;
   outline: none;
   border: none;
   background: ${props => props.theme.colors.green100};
   border-radius: 50%;
   transition: 0.2s all linear;
   margin-left: 22px;
   box-shadow: ${props => props.active && '0 0 0 8px rgba(0, 160, 149, 0.1)'};
   
   :last-of-type {
    margin-left: 0;
    margin-right: 22px;
   }
`

type WrapperProps = {
  margin?: boolean
  line?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: ${props => props.margin && '16px'};
  
  :first-of-type {
    ::before {
      content: none;
    }
  }
  
  &::before {
    content: '';
    position: absolute;
    width: 60%;
    height: 1px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
   
    background: ${props => props.theme.colors.green100};
    
  }
  
`
