import React from 'react'
import Layout from 'layouts/default'
import Hero from 'components/hero'

import InfoSection from 'components/pages/about-us/info'
import IndustrySection from 'components/pages/about-us/industry'
import TeamSection from 'components/pages/about-us/team'

import { Container } from 'components/common/styles'

import HeroImage from 'assets/images/about/hero.jpg'

const AboutPage: React.FC = props => {
  React.useEffect(() => {
    window.analytics.page('About Us')
  }, [])
  return (
    <Layout title='About Us | ComCard'>
      <Hero
        title="The next generation of corporate cards with an integrated loyalty system is here."
        image={HeroImage}
      />
      <Container>
        <InfoSection />
        <IndustrySection />
      </Container>
      <TeamSection />
      {/*<Container>*/}
      {/*  <InvestorsSection />*/}
      {/*</Container>*/}

    </Layout>
  )
}

export default AboutPage
